MultiMeasurement.STATUS_EVENT = 'onStatus'

MultiMeasurement.STATUS = {
    START: 'START',
    POINT_ADDED: 'POINT_ADDED',
    ANCHOR_ADDED: 'ANCHOR_ADDED',
    POINT_REMOVE: 'POINT_REMOVE',
    COMPLETE: 'COMPLETE',
    MISSING_POINT: 'MISSING_POINT',
    TRYING_MORE_POINT: 'TRYING_MORE_POINT',
    BAD_COMPLETE: 'BAD_COMPLETE',
    FAIL: 'FAIL',
    FINISHED: 'FINISHED'
}

MultiMeasurement.TYPES = {
    DEFAULT: 'DEFAULT',
    POLYGON: 'POLYGON',
    HLINE: 'HLINE',
    SQUARE: 'SQUARE'

}

Object.freeze(MultiMeasurement.STATUS)
Object.freeze(MultiMeasurement.TYPES)

MultiMeasurement.prototype = Object.create(AnkaPanAPI.PanoPlugin.prototype)
MultiMeasurement.prototype.constructor = MultiMeasurement
MultiMeasurement._instances = []
MultiMeasurement._colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0x00ffff, 0xff00ff]

MultiMeasurement.clicks = []
function MultiMeasurement(ops) {
    this._clickData = []
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)

    this.onKeyUp = this.onKeyUp === undefined ? this.onKeyUp.bind(this) : this.onKeyUp

    MultiMeasurement._instances.push(this)
    this._labelDictionary = []
    AnkaPanAPI.PanoPlugin.apply(this, [])
    this._zoomLevels = [0.6, 0.3, 0.1]
    this._rayCaster = new THREE.Raycaster()

    var index = AnkaPanAPI.PanoGL.getInstanceCount()
    if (index < 0) {
        console.warn('MultiMeasurement Plugin should be created before PanoGL instance!')
    }

    var colorIndex = index - 1

    if (MultiMeasurement._colors.length <= colorIndex) {
        this.rayColor = Math.round(0xffffff)
    } else {
        this.rayColor = MultiMeasurement._colors[colorIndex]
    }

    this.ClassInstance = MultiMeasurement
    this.initialOptions = ops

    this._tempMesh = []
    if (ops) {
        for (var str in ops) {
            this['_' + str] = ops[str]
        }
    }
}

MultiMeasurement.prototype.prepare = function () {
    this.mScreen = new THREE.Scene()
    var bo = this.baseObject
    var plgns = bo.getPlugins()
    for (var i = 0; i < plgns.length; i++) {
        if (plgns[i] instanceof AnkaScalable.ScalablePlugin) {
            this.scalable = plgns[i]
        }

        if (plgns[i] instanceof AnkaSoftText.SoftTextPlugin) {
            this.softText = plgns[i]
        }
    }

    var p = AnkaPanAPI.PanoGL
    this.baseObject.addEvent(p.RENDER_AFTER_GROUND, this, this.renderAfterGround)
    this.baseObject.addEvent(p.DATA_CHANGE, this, this.onDataChange)
    let that = this
    this.baseObject.getMultiMeasurePlugin = function () {
        return that
    }

    if (this.isStarted) {
        var layer = ''
        this.startMultiMeasureInstance(layer, this.currentPolygonStyle)
        this.waitingForRayUpdateOnDataChange = true
    }
}

MultiMeasurement.prototype.renderAfterGround = function () {
    var bo = this.baseObject
    var renderer = bo.getRenderer()
    var cam = bo.getMainCamera()

    renderer.clearDepth()
    renderer.render(this.mScreen, cam)
}

MultiMeasurement.prototype.onDataChange = function (e) {
    if (this.waitingForRayUpdateOnDataChange) {
        this.clearVisibleRays()
        this.updateVisibleRayObjects()
        this.waitingForRayUpdateOnDataChange = true
    }
}

MultiMeasurement.prototype.getRayObject = function (d, i) {
    var direction = d.ray.direction

    var pitch = Math.asin(direction.y)
    var yaw = Math.atan2(direction.z, direction.x)
    var yawDeg = (yaw / Math.PI * 180) + 90

    var lat = d.lat
    var lon = d.lon
    var alt = d.alt

    var destinationLat = AnkaPanAPI.Utils.destinationPoint(lat, lon, 100, yawDeg)

    var rayObject = { lat: lat, lon: lon, alt: alt, endPoint: destinationLat, yawDeg: yawDeg, pitch: pitch, direction: direction }
    rayObject.id = i// this.currentPoint;

    return rayObject
}

MultiMeasurement.prototype.getRayObject_deprecated = function (d, i) {
    var dp = d.getRayAt(0)
    var direction = dp.ray.direction

    var pitch = Math.asin(direction.y)
    var yaw = Math.atan2(direction.z, direction.x)
    var yawDeg = (yaw / Math.PI * 180) + 90

    var lat = dp.lat
    var lon = dp.lon
    var alt = dp.alt

    var destinationLat = AnkaPanAPI.Utils.destinationPoint(lat, lon, 100, yawDeg)

    var rayObject = { lat: lat, lon: lon, alt: alt, endPoint: destinationLat, yawDeg: yawDeg, pitch: pitch, direction: direction }
    rayObject.id = i// this.currentPoint;

    return rayObject
}

MultiMeasurement.prototype.getCross = function (aRay, bRay) {
    var lint = MultiMeasurement.checkLineIntersection

    var aEndPoint = aRay.endPoint
    var bEndPoint = bRay.endPoint

    var a = new THREE.Vector3(aRay.lon, aRay.alt, aRay.lat)
    var b = new THREE.Vector3(aEndPoint.lon, aRay.alt, aEndPoint.lat)

    var c = new THREE.Vector3(bRay.lon, bRay.alt, bRay.lat)
    var d = new THREE.Vector3(bEndPoint.lon, bRay.alt, bEndPoint.lat)

    var intersectXZ = lint(a.x, a.z, b.x, b.z, c.x, c.z, d.x, d.z)

    if (intersectXZ.l1 && intersectXZ.l2) {
        var distA = AnkaPanAPI.Utils.haversine(a.z, intersectXZ.y, a.x, intersectXZ.x)
        var altA = (Math.tan(aRay.pitch) * distA) + aRay.alt

        var distB = AnkaPanAPI.Utils.haversine(c.z, intersectXZ.y, c.x, intersectXZ.x)
        var altB = (Math.tan(bRay.pitch) * distB) + bRay.alt
        var altAverage = (altA + altB) / 2

        return { lat: intersectXZ.y, lon: intersectXZ.x, alt: altAverage }
    }

    return null
}

MultiMeasurement.prototype.clearVisibleRays = function () {
    for (var i = 0; i < this._tempMesh.length; i++) {
        var mesh = this._tempMesh[i].userData.m
        var meshMaterial = mesh.material
        mesh.parent.remove(mesh)
        this._tempMesh[i].parent.remove(this._tempMesh[i])

        meshMaterial.dispose()
    }
    this._tempMesh.length = 0
}

MultiMeasurement.prototype.createVisibleRay = function (mgeom, color) {
    var cd = this.getCurrentData()
    var d = mgeom
    var ray = mgeom.ray
    var dir = ray.direction

    var pos = AnkaScalable.ScalablePlugin.loc2Pos(cd, d.lon, d.lat, d.alt, this.scalable.camHegInPix, this.scalable.camHegInMet)
    var pitch = Math.asin(dir.y) - (Math.PI / 2)
    var yaw = Math.atan2(dir.x, dir.z)

    if (!this.epipolarLineGeom) {
        this.epipolarLineGeom = new THREE.CylinderBufferGeometry(2.5, 2.5, 4000, 32)
    }

    var cg = this.epipolarLineGeom
    var mat = new THREE.MeshBasicMaterial({ color: color, depthTest: false, depthWrite: false, transparent: true, opacity: 0.4 })
    var m = new THREE.Mesh(cg, mat)
    var o = new THREE.Object3D()
    m.position.set(0, 2000, 0)
    o.add(m)
    o.position.set(pos.x, pos.y, pos.z)

    o.userData.m = m
    o.rotation.set(pitch, yaw + (Math.PI), 0, 'YXZ')
    this.scalable.scalableScene.add(o)
    this._tempMesh.push(o)
    this.setDirty()
}

MultiMeasurement.prototype._onClick = function (event) {
    if (this._pointCount > 0 && this._clickData.length === this._pointCount) {
        var multiEvent = { type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.TRYING_MORE_POINT, targetGeom: MultiMeasurement.tempData }
        this.throwEventForEachInstance(multiEvent)
        return
    }

    var k = this.getCurrentPointKey()
    if (k !== null) {
        var cp = this.getCurrentData()
        var lat = cp.lat
        var lon = cp.lon
        var alt = cp.altitude
        var mouse = new THREE.Vector2()
        var camera = this.baseObject.getMainCamera()
        mouse.x = (event.offsetX / this.baseObject.getRendererDom().clientWidth) * 2 - 1
        mouse.y = -(event.offsetY / this.baseObject.getRendererDom().clientHeight) * 2 + 1
        this._rayCaster.setFromCamera(mouse, camera)
        var dir = this._rayCaster.ray.direction
        var ray = { direction: { x: dir.x, y: dir.y, z: dir.z } }
        var pitch = Math.asin(dir.y)
        var yaw = Math.atan2(dir.z, dir.x)
        var obj3d = this.getAnchorObject(yaw, pitch)
        var nextPointCount = this._clickData.length + 1
        var labelObject = { obj: obj3d, clickPos: '#' + nextPointCount }
        this.scalable.scalableScene.add(obj3d)
        this._labelDictionary.push(labelObject)
        this._clickData.push({ lon: lon, lat: lat, alt: alt, ray: ray })
        this.softText.addObject3D(obj3d, this.baseObject.getMainCamera(), labelObject.clickPos)
        var isUpdated = this.updateOtherInstances()

        // yaw * 180 / Math.PI

        this.throwAnchorEventForEachInstance(yaw)

        // TODO
        if (isUpdated) {
            this.throwClickEventForEachInstance()
        }
    }
}

MultiMeasurement.prototype.getAnchorObject = function (yaw, pitch) {
    if (!MultiMeasurement.__anchorTexture) {
        var t = AnkaPanAPI.AssetManager.getInstance().GetAssetNoCache('img/multi/crosshair.png')
        t.generateMipmaps = false
        t.minFilter = THREE.LinearFilter
        MultiMeasurement.__anchorTexture = t
    }

    if (!MultiMeasurement.__anchorGeom) {
        var geo = new THREE.Geometry()
        var vertex = new THREE.Vector3()
        geo.vertices.push(vertex)
        MultiMeasurement.__anchorGeom = geo
    }

    var geom = MultiMeasurement.__anchorGeom
    var material = new THREE.PointsMaterial({
        size: 25,
        transparent: true,
        alphaTest: 0.5,
        sizeAttenuation: false,
        map: MultiMeasurement.__anchorTexture,
        depthTest: true,
        color: this.rayColor
    })
    var obj3d = new THREE.Points(geom, material)
    var ox = Math.cos(yaw) * 1000
    var oy = Math.tan(pitch) * 1000
    var oz = Math.sin(yaw) * 1000
    obj3d.position.set(ox, oy, oz)
    return obj3d
}

MultiMeasurement.prototype.updateOtherInstances = function () {
    for (var i = 0; i < MultiMeasurement._instances.length; i++) {
        var mm = MultiMeasurement._instances[i]
        mm.clearVisibleRays()
        mm.updateVisibleRayObjects()
        var updatedRays = mm.updateRays()
        var totalPoints = mm.calculateTotalPositions(updatedRays)
        if (totalPoints) {
            mm.updateCommonGeometry(totalPoints)
        } else {
            mm.throwEvent({ type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.FAIL, bo: mm.baseObject.contentDV, message: mm.calculateErros })
            // mm.throwEvent({ type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.FINISHED, isSuccessful: false, message: mm.calculateErros })
        }
    }
}

MultiMeasurement.prototype.throwClickEventForEachInstance = function () {

    var m = MultiMeasurement._instances
    for (var i = 0; i < m.length; i++) {
        m[i].throwEvent({ currentTarget: this, type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.POINT_ADDED, targetGeom: MultiMeasurement.tempData })
    }
}

MultiMeasurement.prototype.throwAnchorEventForEachInstance = function (ydegree) {
    var m = MultiMeasurement._instances

    for (var i = 0; i < m.length; i++) {
        m[i].throwEvent({ currentTarget: this, anchorYDegree: (Math.PI * 2) - ydegree, type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.ANCHOR_ADDED, targetGeom: MultiMeasurement.tempData })
    }
}

MultiMeasurement.prototype.getAttachedPanogl = function () {
    return this.baseObject
}

MultiMeasurement.prototype.getRayAt = function (index) {
    if (index > -1 && index < this._clickData.length) {
        return this._clickData[index]
    }

    return null
}

MultiMeasurement.prototype.getRayCount = function () {
    return this._clickData.length
}

MultiMeasurement.prototype.updateVisibleRayObjects = function () {
    var muar = MultiMeasurement._instances
    for (var i = 0; i < muar.length; i++) {
        var ray = muar[i].getRayAt(this._clickData.length)
        if (ray) {
            this.createVisibleRay(ray, muar[i].rayColor)
        }
    }
}

MultiMeasurement.prototype.updateRays = function () {
    var muar = MultiMeasurement._instances
    var rays = []
    for (var i = 0; i < muar.length; i++) {
        var ins = muar[i]
        var ar = []
        for (var j = 0; j < ins.getRayCount(); j++) {
            ar.push(ins.getRayAt(j))
        }
        rays.push(ar)
    }

    this.updatedRays = rays
    return rays
}

MultiMeasurement.prototype.calculateTotalPositions = function (dataMatrix) {
    let errors = []
    let index = 0
    for (let i = 0; i < dataMatrix.length; i++) {
        for (let j = 0; j < dataMatrix[i].length; j++) {
            dataMatrix[i][j].rayObject = this.getRayObject(dataMatrix[i][j], index)
            index++
        }
    }

    let crosses = []
    let pointAverages = []

    let biggest = -1
    for (let i = 0; i < dataMatrix.length; i++) {
        for (let j = 0; j < dataMatrix[i].length; j++) {
            if (dataMatrix[i][j]) {
                if (j >= biggest) {
                    biggest = j + 1
                }
            }
        }
    }

    for (let i = 0; i < biggest; i++) {
        pointAverages.push({ lat: 0, lon: 0, alt: 0 })
        crosses[i] = []
        for (let j = 0; j < dataMatrix.length; j++) {
            for (let k = j; k < dataMatrix.length; k++) {
                if (dataMatrix[j][i] && dataMatrix[k][i]) {
                    let ro1 = dataMatrix[j][i].rayObject
                    let ro2 = dataMatrix[k][i].rayObject
                    if (ro1 !== ro2) {
                        let c = this.getCross(ro1, ro2)
                        if (c) {
                            crosses[i].push(c)
                        } else {
                            errors.push(j, i, k, i)
                        }
                    }
                }
            }
        }
    }

    if (errors.length > 0) {
        this.calculateErros = errors
        return null
    }

    for (let i = 0; i < pointAverages.length; i++) {
        let total = pointAverages[i]
        let corssAr = crosses[i]
        let s = corssAr.length

        for (let j = 0; j < s; j++) {
            total.lat += corssAr[j].lat
            total.lon += corssAr[j].lon
            total.alt += corssAr[j].alt
        }

        total.isValid = s > 0

        total.lat = total.lat / s
        total.lon = total.lon / s
        total.alt = total.alt / s
    }

    return pointAverages
}

MultiMeasurement.prototype.updateCommonGeometry = function (calculatedPoints) {
    let validCounter = 0
    for (let i = 0; i < calculatedPoints.length; i++) {
        if (calculatedPoints[i].isValid) {
            validCounter++
        }
    }

    let dh = MultiMeasurement.tempData
    if (!MultiMeasurement.tempData) {
        if (validCounter > 1) {
            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SLine, [], {})
            dh.type = AnkaScalable.GeomDataHolder.LINE
            dh.isDrawnData = true
            dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
        }
    } else {
        dh.resetPoints()
    }

    if (dh) {
        for (let i = 0; i < validCounter; i++) {
            dh.addPoint(calculatedPoints[i])
        }
    }

    if (MultiMeasurement.tempData) {
        let lyrs = dh.layer.getLayerInstances()
        for (let i = 0; i < lyrs.length; i++) {
            lyrs[i].removeConnectedChildren(MultiMeasurement.tempData)
        }

        MultiMeasurement.tempData = null
    }

    if (dh) {
        if (!MultiMeasurement.tempData) {
            MultiMeasurement.tempData = dh
        }

        if (this.layer) {
            dh.layer = this.layer
        } else {
            dh.layer = this.scalable.getMainSketchLayer()
        }

        dh.layer.addToList(dh)

        let lyrs = dh.layer.getLayerInstances()
        for (let i = 0; i < lyrs.length; i++) {
            lyrs[i].drawGeometry(dh)
        }

        dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
    }

    this.scalable.updateSavedGeoms()
}

MultiMeasurement.prototype.refreshEpipolars = function () {
    var previousPointID = this._clickData.length
    var clickIndex = previousPointID % this.pointCount
    var pointIndex = Math.floor(previousPointID / this.pointCount)

    this.clearVisibleRays()
    for (var i = 0; i < pointIndex; i++) {
        var row = i * this.pointCount
        var column = clickIndex
        var index = row + column
        this.createVisibleRay(this._clickData[index])
    }
}

MultiMeasurement.prototype.getClickedAreaCanvas = function () {
    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')

    var memoryCanvas = document.createElement('canvas')
    var memoryContext = memoryCanvas.getContext('2d')

    var x = this.baseObject.globalMouseOffsetX
    var y = this.baseObject.globalMouseOffsetY

    var width = 100
    var height = 100

    canvas.width = width
    canvas.height = height

    memoryCanvas.width = width
    memoryCanvas.height = height

    var gl = this.baseObject.getRenderer().context

    var pixels = new Uint8Array(4 * width * height)
    gl.readPixels(x - (width / 2), y - (height / 2), width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels)

    var imageData = memoryContext.createImageData(width, height)

    for (var i = 0; i < pixels.length; i += 4) {
        imageData.data[i] = pixels[i] // red
        imageData.data[i + 1] = pixels[i + 1] // green
        imageData.data[i + 2] = pixels[i + 2] // blue
        imageData.data[i + 3] = pixels[i + 3] // alpha
    }

    memoryContext.putImageData(imageData, 0, 0)

    context.save()
    context.scale(1, -1)
    context.drawImage(memoryCanvas, 0, -width)
    context.restore()

    context.beginPath()
    context.moveTo(width / 2, 0)
    context.lineTo(width / 2, height)

    context.moveTo(0, height / 2)
    context.lineTo(width, height / 2)
    context.strokeStyle = '#ffffff'
    context.lineWidth = 1
    context.stroke()

    return canvas
}

MultiMeasurement.prototype.getCurrentPointKey = function () {
    if (this.baseObject && this.baseObject.dataParser.currentPoint) {
        var cp = this.baseObject.dataParser.currentPoint
        return cp.latlon
    } else {
        return null
    }
}

MultiMeasurement.prototype.getCurrentData = function () {
    if (this.baseObject && this.baseObject.dataParser.currentPoint) {
        var cp = this.baseObject.dataParser.currentPoint
        return cp
    } else {
        return null
    }
}

MultiMeasurement.prototype.startMultiMeasure = function (layer, type, pointCount) {
    var types = MultiMeasurement.TYPES
    if (type === types.SQUARE || type === types.HLINE) {
        pointCount = 2
    } else if (!pointCount) {
        pointCount = -1
    }

    for (var i = 0; i < MultiMeasurement._instances.length; i++) {
        MultiMeasurement._instances[i].startMultiMeasureInstance(layer, type, pointCount)
    }
    this.throwEvent({ type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.START })
}

MultiMeasurement.prototype.startMultiMeasureInstance = function (layer, type, pointCount) {
    this.isStarted = true
    this.calculateErros = ''
    this.layer = layer || this.scalable.getMainSketchLayer()
    this.currentPolygonStyle = type
    this._pointCount = pointCount
    this.scalable.disableMouse()
    this.currentPoint = 0
    this.wasArrowHidden = this.baseObject.getArrowVisibility()
    this.baseObject.setArrowVisibility(false)
    var gm = this.baseObject.getGroundMaster()
    gm.disableNavigation()
    this.baseObject.disableGroundNav(true)
    this.magnifier = new AnkaDraw.Magnifier(this.baseObject.contentDV)
    var rdom = this.baseObject.getRendererDom()

    rdom.addEventListener('mousemove', this.onMouseMove)
    rdom.addEventListener('mousedown', this.onMouseDown)
    rdom.addEventListener('mouseup', this.onMouseUp)
    this.baseObject.addEvent(AnkaPanAPI.PanoGL.MOUSE_LEAVE, this, this.onMouseLeave)
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
}

MultiMeasurement.prototype.stopMultiMeasure = function (isSuccessful) {
    if (this.keyUpFunction) {
        document.removeEventListener('keyup', this.keyUpFunction)
    }

    if (this.wasArrowHidden !== undefined) {
        this.baseObject.setArrowVisibility(this.wasArrowHidden)
    }

    this.wasArrowHidden = undefined
    this.scalable.enableMouse()
    this.currentPoint = NaN

    if (this._labelDictionary) {
        for (var i = 0; i < this._labelDictionary.length; i++) {
            var anchor = this._labelDictionary[i].obj
            this.softText.removeObject3D(anchor)
            if (anchor.parent) {
                anchor.parent.remove(anchor)
                anchor.material.dispose()
            }
        }
    }

    this.clearVisibleRays()
    this._labelDictionary = []
    this._clickData = []
    this._tempMesh.length = 0
    this.allowClick = false
    this.clickCount = NaN
    this.pointCount = NaN

    var gm = this.baseObject.getGroundMaster()
    gm.enableNavigation()
    this.baseObject.disableGroundNav(false)
    AnkaPanAPI.CursorManager.setCursor('auto', -1, true)
    this.allowMagnifier = false

    if (this.magnifier) {
        this.magnifier.dispose()
    }

    this.isStarted = false
    var rdom = this.baseObject.getRendererDom()
    rdom.removeEventListener('mousemove', this.onMouseMove)
    rdom.removeEventListener('mousedown', this.onMouseDown)
    rdom.removeEventListener('mouseup', this.onMouseUp)

    this.baseObject.removeEvent(AnkaPanAPI.PanoGL.MOUSE_LEAVE, this.onMouseLeave)
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)

    this.setDirty()

    if (isSuccessful) {
        this.scalable.updateSavedGeoms()
    }
}

MultiMeasurement.prototype.notifyNewInstance = function () {
    if (this.isStarted) {
        for (var i = 0; i < MultiMeasurement._instances.length; i++) {
            var m = MultiMeasurement._instances[i]
            m.isStarted = this.isStarted
            m.currentPolygonStyle = this.currentPolygonStyle
        }
    }
}

MultiMeasurement.prototype.onMouseLeave = function (e) {
    if (this.magnifier) {
        this.magnifier.hide()
    }

    this.allowMagnifier = false
}
MultiMeasurement.prototype.onMouseDown = function (e) {
    if (e.button === 0) {
        this.mouseDownX = e.offsetX
        this.mouseDownY = e.offsetY
    }
}

MultiMeasurement.prototype.onMouseUp = function (e) {
    if (e.button === 0) {
        if (Math.abs(this.mouseDownX - e.offsetX) < 2 && Math.abs(this.mouseDownY - e.offsetY) < 2) {
            this._onClick(e)
        }
        this.mouseDownX = NaN
        this.mouseDownY = NaN
    } else if (e.button === 1) {
        this.removePreviousPoint()
    } else if (e.button === 2) {
        this.stopMultiMeasureInAllInstances(true)
    }
}

MultiMeasurement.prototype.stopMultiMeasureInAllInstances = function (isSuccessful) {
    if (!isSuccessful) {
        let m = MultiMeasurement._instances
        for (let i = 0; i < m.length; i++) {
            m[i].stopMultiMeasure(isSuccessful)
        }

        this._clearTempMesh()
        return
    }

    if (!this.isStarted) {
        return
    }

    let updatedRays = this.updateRays()
    let totalPoints = this.calculateTotalPositions(updatedRays)
    let validPointsCount = 0

    if (totalPoints) {
        for (let i = 0; i < totalPoints.length; i++) {
            if (totalPoints[i].isValid) {
                validPointsCount++
            }
        }
    }

    if (this._pointCount > 0 && this._pointCount !== validPointsCount) {
        let event = { type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.MISSING_POINT, targetGeom: MultiMeasurement.tempData }
        this.throwEventForEachInstance(event)
        return
    }

    this.isStarted = false
    this._clearTempMesh()
    let dh
    if (validPointsCount > 0) {
        let pSize = validPointsCount
        if (pSize === 1) {
            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPoint, [], {})
        } else if (pSize === 2 && this.currentPolygonStyle === MultiMeasurement.TYPES.SQUARE) {
            let maxAlt = Number.MIN_SAFE_INTEGER
            let minAlt = Number.MAX_SAFE_INTEGER
            for (let i = 0; i < totalPoints.length; i++) {
                if (totalPoints[i].alt > maxAlt) { maxAlt = totalPoints[i].alt }

                if (totalPoints[i].alt < minAlt) { minAlt = totalPoints[i].alt }
            }

            let point1 = totalPoints[0]
            let point2 = totalPoints[1]

            let p1 = { lon: point1.lon, lat: point1.lat, alt: maxAlt }
            let p2 = { lon: point2.lon, lat: point2.lat, alt: maxAlt }

            let p3 = { lon: point2.lon, lat: point2.lat, alt: minAlt }
            let p4 = { lon: point1.lon, lat: point1.lat, alt: minAlt }

            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPolygon, [], {})
            dh.addPoint(p1)
            dh.addPoint(p2)
            dh.addPoint(p3)
            dh.addPoint(p4)
            // dh.addPoint(totalPoints[i]);
        } else if (pSize === 2 && this.currentPolygonStyle === MultiMeasurement.TYPES.HLINE) {
            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SHLine, [], {})
        } else if (pSize > 1 && this.currentPolygonStyle === MultiMeasurement.TYPES.DEFAULT) {
            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SLine, [], {})
        } else if (pSize > 2 && this.currentPolygonStyle === MultiMeasurement.TYPES.POLYGON) {
            dh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPolygon, [], {})
        } else {
            this.calculateErros += 'Not enough points for creating selected type geometry'
            isSuccessful = false
        }

        if (dh) {
            if (this.currentPolygonStyle !== MultiMeasurement.TYPES.SQUARE) {
                for (let i = 0; i < pSize; i++) {
                    dh.addPoint(totalPoints[i])
                }
            }

            dh.isDrawnData = true
            dh.layer = this.layer
            dh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)

            this.layer.addToList(dh)
            let lyrs = this.layer.getLayerInstances()

            for (let i = 0; i < lyrs.length; i++) {
                lyrs[i].drawGeometry(dh)
            }
        }

        this.throwCompleteEventForEachInstance(isSuccessful, dh)
    } else {
        this.calculateErros += 'Not enough points for creating selected type geometry'
        isSuccessful = false
        this.throwCompleteEventForEachInstance(isSuccessful, dh)
    }

    let m = MultiMeasurement._instances
    for (let i = 0; i < m.length; i++) {
        m[i].stopMultiMeasure(isSuccessful)
    }
}

MultiMeasurement.prototype._clearTempMesh = function () {
    if (MultiMeasurement.tempData) {
        // this.layer.removeConnectedChildren(MultiMeasurement.tempData);

        var lyrs = this.layer.getLayerInstances()
        for (var i = 0; i < lyrs.length; i++) {
            lyrs[i].removeConnectedChildren(MultiMeasurement.tempData)
        }

        if (this.layer.isSketchLayer) {
            this.layer.removeFromCommonList(MultiMeasurement.tempData)
        } else {
            console.log('Not impelemeted?')
        }

        MultiMeasurement.tempData = null
    }
}

MultiMeasurement.prototype.removePreviousPoint = function () {
    if (this._clickData.length > 0) {
        var index = this._clickData.length - 1
        this._clickData.splice(index, 1)

        var removedLabels = this._labelDictionary.splice(index, 1)

        if (removedLabels && removedLabels.length > 0) {
            this.softText.removeObject3D(removedLabels[0].obj)
            var anchor = removedLabels[0].obj
            if (anchor.parent) {
                anchor.parent.remove(anchor)
                anchor.material.dispose()
            }
        }

        this.updateOtherInstances()

        this.throwRemovePointEventForEachInstance()
    }
}

MultiMeasurement.prototype.throwRemovePointEventForEachInstance = function () {
    var m = MultiMeasurement._instances
    for (var i = 0; i < m.length; i++) {
        m[i].throwEvent({ type: MultiMeasurement.STATUS_EVENT, status: MultiMeasurement.STATUS.POINT_REMOVE, targetGeom: MultiMeasurement.tempData })
    }
}

MultiMeasurement.prototype.throwEventForEachInstance = function (event) {
    var m = MultiMeasurement._instances
    for (var i = 0; i < m.length; i++) {
        m[i].throwEvent(event)
    }
}

MultiMeasurement.prototype.throwCompleteEventForEachInstance = function (isSuccessful, geom) {
    var m = MultiMeasurement._instances
    for (var i = 0; i < m.length; i++) {
        if (isSuccessful) {
            m[i].throwEvent({ type: MultiMeasurement.STATUS_EVENT, isSuccessful: isSuccessful, status: MultiMeasurement.STATUS.COMPLETE, drawType: geom.type, targetGeom: geom })
            m[i].throwEvent({ type: MultiMeasurement.STATUS_EVENT, isSuccessful: isSuccessful, status: MultiMeasurement.STATUS.FINISHED, drawType: geom.type, targetGeom: geom })
        } else {
            m[i].throwEvent({ type: MultiMeasurement.STATUS_EVENT, isSuccessful: isSuccessful, message: this.calculateErros, status: MultiMeasurement.STATUS.BAD_COMPLETE, targetGeom: geom })
        }
    }
}

MultiMeasurement.prototype.onKeyUp = function (e) {
    if (e.keyCode === 27) {
        this.stopMultiMeasureInAllInstances(false)
    } else if (e.keyCode === 16) {
        this.magnifier.hide()
        this.allowMagnifier = false
    }
}

MultiMeasurement.prototype.onKeyDown = function (e) {
    if (!this.baseObject.isUserActive) return

    if (e.keyCode === 16) {
        this.magnifier.show()
        this.allowMagnifier = true
        this.onMouseMove()
    }
}

MultiMeasurement.prototype.onMouseMove = function (e) {
    if (this.allowMagnifier) {
        if (this.magnifier) {
            if (e) {
                this.magnifier.renderImage(this.baseObject, e.offsetX, e.offsetY)
            } else {
                this.magnifier.renderImage(this.baseObject, this.baseObject.globalMouseOffsetX, this.baseObject.globalMouseOffsetY)
            }
        }
    }

    if (!AnkaPanAPI.PanoGL.isPointerOnClickableObject) {
        AnkaPanAPI.CursorManager.setCursor('crosshair', 666)
    } else {
        AnkaPanAPI.CursorManager.setCursor('auto', -1, true)
    }
}

MultiMeasurement.checkLineIntersection = function (line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) {
    var denominator, a, b, numerator1, numerator2
    var result = {
        x: null,
        y: null,
        l1: false,
        l2: false
    }

    denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY))
    if (denominator === 0) {
        return result
    }

    a = line1StartY - line2StartY
    b = line1StartX - line2StartX
    numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b)
    numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b)
    a = numerator1 / denominator
    b = numerator2 / denominator

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1StartX + (a * (line1EndX - line1StartX))
    result.y = line1StartY + (a * (line1EndY - line1StartY))
    /*
        // it is worth noting that this should be the same as:
        x = line2StartX + (b * (line2EndX - line2StartX));
        y = line2StartX + (b * (line2EndY - line2StartY));
        */
    // if line1 is a segment and line2 is infinite, they intersect if:
    if (a > 0 && a < 1) {
        result.l1 = true
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
        result.l2 = true
    }

    return result
}

export { MultiMeasurement }
